<template>
  <div>
    <a-card :title="$t('出库报表')">
      <a-row gutter="16">
        <a-col :span="24" :md="8" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-range-picker :value="[searchForm.start_date, searchForm.end_date]" @change="onChangePicker" />
        </a-col>
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.warehouse" :placeholder="$t('仓库')" allowClear style="width: 100%" @change="search">
            <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <client-select v-model="searchForm.client" :placeholder="$t('货主')" @change="search"></client-select>
        </a-col>
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <OrderTypeSelect v-model="searchForm.order_type" :placeholder="$t('订单类型')" @change="search" />
        </a-col>
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-input v-model="searchForm.search" :placeholder="$t('单据编号')" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-input v-model="searchForm.material_number" :placeholder="$t('产品编号')" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-input v-model="searchForm.batch_number" :placeholder="$t('批次编号')" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-input v-model="searchForm.external_number" :placeholder="$t('往来单位单号')" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">{{ $t("查询") }}</a-button>
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 100px; margin-bottom: 12px">
          <a-button icon="download" @click="exportExcel">{{ $t("导出") }}</a-button>
        </a-col>
      </a-row>
      <a-row>
        <a-table
          size="small"
          :columns="columns"
          :dataSource="items"
          :loading="loading"
          :pagination="pagination"
          @change="tableChange"
          :scroll="{ x: 3040 }"
        >
          <template slot="number" slot-scope="value, item, index">
            <a @click="handleDetail(item.delivery_order)">{{ value }}</a>
          </template>
        </a-table>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { warehouseOption } from "@/api/option";
import { StockOutReportList, StockOutReportExport } from "@/api/statistic";
import columns from "./columns.js";
import { exportExcel } from "@/utils/excel";
import moment from "moment";

export default {
  components: {
    ClientSelect: () => import("@/components/ClientSelect/"),
    OrderTypeSelect: () => import("@/components/OrderTypeSelect/"),
  },
  data() {
    return {
      columns,
      searchForm: { search: "", page: 1, ordering: undefined },
      pagination: { current: 1, total: 0, pageSize: 16 },
      loading: false,
      items: [],

      visible: false,
      targetItem: {},
      form: {},

      warehouseItems: [],
      materialItems: [],
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
  },
  methods: {
    initialize() {
      this.searchForm.warehouse = this.currentWarehouse;
      this.searchForm = { ...this.searchForm, ...this.$functions.parseParams(this.$route.query) };
      this.list();
      warehouseOption({ page_size: 999999 }).then((data) => {
        this.warehouseItems = data.results;
      });
    },
    list() {
      let searchForm = { ...this.searchForm };
      if (searchForm.end_date) {
        searchForm.end_date = moment(searchForm.end_date).add(1, "days").format("YYYY-MM-DD");
      }

      this.loading = true;
      this.$router.push({ query: this.searchForm });

      StockOutReportList(searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onChangePicker(date, dateString) {
      let startDate = date[0],
        endDate = date[1];
      this.searchForm.start_date = startDate ? startDate.format("YYYY-MM-DD") : undefined;
      this.searchForm.end_date = endDate ? endDate.format("YYYY-MM-DD") : undefined;
      this.search();
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    exportExcel() {
      let searchForm = { ...this.searchForm };
      if (searchForm.end_date) {
        searchForm.end_date = moment(searchForm.end_date).add(1, "days").format("YYYY-MM-DD");
      }

      StockOutReportExport(searchForm)
        .then((resp) => {
          exportExcel(resp, this.$t("出库报表"));
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    handleDetail(id) {
      this.$router.push({ path: "/stock_out/delivery_task_detail", query: { id } });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped></style>
